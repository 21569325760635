<template>
  <v-card>
    <v-card-title>Sugestões de Melhoria</v-card-title>
    <v-card-text>
      <v-simple-table id="tabela" v-show="melhorias.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <!--th class="text-center">Id</th-->
              <th class="text-center">Área</th>
              <th class="text-center">Prioridade</th>
              <th class="text-center">Email</th>
              <th class="text-center">Telefone</th>
              <th class="text-center">Descrição</th>
              <th class="text-center">Imagens</th>
              <th class="text-center">Criado em</th>
              <!--th class="text-center">Atualizado em</th-->
              <th class="text-center">Finalizado em</th>
              <th class="text-center">Descrição da Finalização</th>
              <th class="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in melhorias" :key="index">
              <!--td class="text-center">{{ item.id }}</td-->
              <td class="text-center">{{ item.type }}</td>
              <td class="text-center">{{ item.priority }}</td>
              <td class="text-center">{{ item.email }}</td>
              <td class="text-center">{{ item.phone }}</td>
              <td class="text-center"><span class="max-lines">{{ item.description }}</span></td>
              <td class="text-center">
                <CardImgList :items="picItems(item.pic)" :showBadge="false" />
              </td>
              <td class="text-center">{{ new Date(item.createdAt).toLocaleString() }}</td>
              <!--td class="text-center">{{ new Date(item.updatedAt).toLocaleString() }}</td-->
              <td class="text-center">{{ `${item.dateFinish ? new Date(item.dateFinish).toLocaleString() : ''}`  }}</td>
              <td class="text-center" >{{item.descriptionFinish}}</td>
              <td class="text-center">
                <!-- botão editar -->
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editar(item)"
                      color="warning"
                      class="mr-1"
                    >mdi-pencil</v-icon>
                  </template>
                  <span>Editar Sugestão</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <!-- dialog de cadastro -->
    <v-dialog v-model="dialog" persistent max-width="700" scrollable>
			<v-card>
				<v-card-title class="text-h5 pr-4 pb-4">{{ melhoria.id ? 'Edição' : 'Cadastro' }} de Sugestão de Melhoria<v-spacer></v-spacer>
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pb-0">
					<v-form ref="formMelhorias">

						<v-select
              :items="['App', 'Web', 'Outros']"
              label="Plataforma da Melhoria"
              v-model="melhoria.type"
              outlined
              :rules="requiredField"
            ></v-select>
            <v-select
              :items="['Alta', 'Média', 'Baixa']"
              label="Prioridade"
              v-model="melhoria.priority"
              outlined
              :rules="requiredField"
            ></v-select>
            <v-text-field
              v-model="melhoria.email"
              label="Seu email"
              outlined
              :rules="requiredField"
            ></v-text-field>
            <v-text-field
              v-model="melhoria.phone"
              label="Seu Telefone"
              v-mask="celMask"
              outlined
              :rules="requiredField"
            ></v-text-field>
            <v-textarea
              label="Descrição da Melhoria"
              v-model="melhoria.description"
              outlined
              counter
              :rules="requiredField"
            ></v-textarea>
            <v-file-input
              show-size
              counter
              multiple
              label="Selecionar Imagens"
              small-chips
              outlined
              v-model="melhoria.picture"
              accept="image/png, image/jpeg, image/gif"
            ></v-file-input>

					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4">
					<v-spacer></v-spacer>
					<v-btn text @click="dialog = false">Cancelar</v-btn>
					<v-btn color="success" @click="save">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1"
          dark
          absolute
          bottom
          right
          fab
          fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Melhoria</span>
    </v-tooltip>	

  </v-card>
</template>

<script>
import { requiredField } from "../../Utils/InputValidation";
import CardImgList from "../Relatorios/CardImgList.vue";

export default {
  data() {
    return {
      dialog: false,
      requiredField,
      celMask: "",
      email: {
        id: null,
        enabled: true,
      },
      melhoria: {
        type: "",
        priority: "",
        email: "",
        phone: "",
        description: "",
        picture:[]
      },
      
    };
  },
  computed: {
    workGroup: {
      get() {
        return this.$store.getters.workGroup;
      },
    },
    melhorias: {
      get() {
        return this.$store.getters.getMelhorias;
      },
    },
    headers: {
      get() {
        return this.$store.getters.getEmailsTableHeader;
      },
    },
  },
  components: {
    CardImgList
},
  methods: {
    get() {
      this.$store.dispatch("loadMelhorias");
    },
    save() {
      if (!this.$refs.formMelhorias.validate())
        return
      this.$store.dispatch("saveMelhoria", this.melhoria);
      setTimeout(() => {
        this.get();
      }, 500);
      this.dialog = false;
    },
    editar(event) {
      this.melhoria = event;
      this.dialog = true;
    },
    delet(event) {
      this.$store.dispatch("deleteMelhoria", event);
    },
    groupSelected() {
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
    picItems(item){
      return Object.values(item).map(pic => pic.path);
    }
  },
  created() {
    this.get();
  },
  watch: {
    dialog(value) {
      if (value){
        if (!this.melhoria.id)
          this.celMask = "(##) ####-#####"; // Cria a máscara na abertura do dialog p/ contornar bug do v-mask
        
        this.$nextTick(() => {
          this.$refs.formMelhorias.resetValidation();
        });        
      } else {
        this.celMask = "";
        this.melhoria = {};
      }
    }
  },
};
</script>

<style scoped>
.max-lines {
  width: 250px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
</style>